<template>
    <div class='paypage'>
        <!-- <van-nav-bar
            title="支付"
            left-arrow
            @click-left="onClickLeft"
        /> -->
        <div class='paycard'>
            <div class='payText'>支付金额(元)</div>
            <div class='payNumber'>{{payAmt}}</div>
        </div>
        <div class='payLine' @click='goback'>
             <div class='lineRight line-rigt-text' v-if='bankSrc==""' >
                请选择银行卡
            </div>
            <div class='lineRight' v-else>
                <img :src='bankSrc' alt="" class='bankIcon'/>
                <div class='bankText'>{{bankName}} ({{bankIn}})</div>
            </div>
            <div class='lineLeft'></div>
        </div>
        <div class='payfrom'>
           <div class='payMobile'>
               <div class='mobileText'>手机号</div>
               <input class='moblieNum'  readonly='readonly'  :value="clientCell" placeholder='请输入手机号'/>
           </div>
           <div class='payMobile payoutline'>
               <div class='mobileText'>验证码</div>
               <input class='moblieNum'  type='number' oninput="if(value.length>6)value=value.slice(0,6)" v-model='verifyCode' @change="changeInput($event)" placeholder="填写验证码"/>
               <div class="mobileCode" @click='GetVerifyCode' v-show="canSendCode">{{ verifyTxt}}</div>
               <div class="mobileCode-min" @click='GetVerifyCode' v-show="!canSendCode">{{count}}秒</div>
           </div>
        </div>
        <!-- 底部 支付协议以及按钮-->
        <div class='payprotocol' >
            <div :class='checked==true ? "pro-icon" : "proIcon-active"' @click="gochencked" ></div>
            <a class='proText'  @click="gourl">同意《快捷支付还款提醒》</a>
        </div>
        <div class='footer'>
            <div class='btn' @click="paySub">去支付</div>
        </div>
        <!-- 添加银行卡 -->
         <Modal :show="showback" @cancel="cancel" :showCancel="true" class='bankpages' :title="'选择收款账户'">
            <!-- <div class='bankmain' .openBankName,item.bankAccount4,item.bankAccount,item> -->
            <div class='modalMain'>
                <div class='modalContain'>
                <div class='modalLine' v-for="item in bankList" :key="item.bankAccount"  @click='checkBank(item)' >
                    <img :src='item.logoImg' alt='' class='lineIcon'/>
                    <div class='lineText' >
                    <div>{{item.openBankName}}（{{item.bankAccountShow}}）</div>
                    <!-- 银行卡的选中的状态 .openBankName+item.bankAccount4-->
                    <div :class="[chenckStatus==item.bankAccountShow ?'checkedIcon':'']"></div>
                    </div>
                </div>
            
                <div class='modalLine' @click="addBank">
                    <div class='lineBank'></div>
                    <div class='lineText'>添加新的银行卡</div>
                </div>
                </div>
            </div>
            <!-- </div> -->
        </Modal>
       
    </div>
</template>
<style scoped lang='less' src='./index.less'></style>
<script>
import Modal from '@/components/Modal/Modal.vue'
import { reactive, toRefs, onMounted } from 'vue'
import { GetUrlParams, isEmptyObj,routerTag} from '../../utils/index'
import {Toast} from 'vant';
import APP from '../../utils/APP'
import API from '../../server/api'
// import {useRouter} from 'vue-router';
export default {
    name: 'Index',
    components: {
        Modal
    },
    setup(){
         const state=reactive({
                
                showback:false,//银行卡
                
                // chenckStatus:false,//选中的状态
                payAmt:0,//还款金额
                bankList:[],//银行卡
                chenckStatus:null,
                bankAccount:'',//银行卡号
                bankSrc:'',
                bankName:'',
                bankIn:'',
                verifyCode: '',
                countdown: 60,
                verifyTxt: "点击获取验证码",
                canSendCode: true,
                clientNo:'',
                productName:'',
                clientCell:'',
                periodNos:[],
                timer: null,//倒计时
                count: '',//倒计时
                checked:true,//协议选中
                payType:''//还款类型
        })
    //    const router = useRouter() //2、使用 路由跳转
        //获取银行卡列表
        const bankinit= async () => {
    
            const result= await API.bankLists({})
            state.bankList=result.bankCardList;
            // console.log( JSON.stringify(state.bankList) ,999);
        
        }
        const checkBank = (item) => {
            state.chenckStatus = item.bankAccountShow
            state.bankSrc=item.logoImg
            state.bankName=item.openBankName
            state.bankIn=item.bankAccountShow
            state.bankAccount=item.bankAccount
            state.clientCell=item.ownerMobile
            state.showback = false
        }
         //获取验证码
        const GetVerifyCode= async ()=>{
            window.TDAPP.onEvent("支付页-获取还款验证码-进入");
            const {bankAccount,payType}=state
            const orderNo=window.localStorage.getItem('orderNo');
            const periods=JSON.parse(window.localStorage.getItem('periodNos'))
            const appNum=window.localStorage.getItem('appNo')
        //    const payType= window.localStorage.getItem('payType')
            if(bankAccount==''){
                Toast('请选择银行卡')
                return false;
            }
            const result= await API.paySmsCode({
                appNo:appNum,
                orderNo,//
                payType:payType,//还款类型，0-正常还款（单，多期还款），1-提前结清
                cardNumber:bankAccount,//银行卡
                periods:periods //期数列表 还款类型为0时，不能为空
            })
            if(result.repaySms.needVerify==1){
                countdowns()
            }else if(result.repaySms.needVerify==-1){
                Toast(result.repaySms.remark)
                return false
            }
            
            
            // console.log(JSON.stringify(result),774);
        } 
        // 验证码倒计时
        const countdowns=() =>{
           const TIME_COUNT = 60;
            if (!state.timer) {
                state.count = TIME_COUNT;
                state.canSendCode = false;
                state.timer = setInterval(() => {
                    if (state.count > 0 && state.count <= TIME_COUNT) {
                        state.count--;
                    } else {
                        state.canSendCode = true;
                        clearInterval(state.timer);
                        state.timer = null;
                    }
                }, 1000)
            }
        }
        //获取验证码的input输入值
        const changeInput=(e)=>{
            console.log(e.target.value,33);
            if(e.target.value=='' || e.target.value.length > 6){
                Toast('请输入正确的验证码')
                return false
            }
            state.verifyCode=e.target.value;
        
        }
        //还款提交
        const paySub=async()=>{
            window.TDAPP.onEvent("支付页-还款提交-进入");
            const {payAmt,bankAccount,clientCell,clientNo,verifyCode,checked,payType}=state;
            const orderNo=window.localStorage.getItem('orderNo');
            const appNum=window.localStorage.getItem('appNo')
             const periods=JSON.parse(window.localStorage.getItem('periodNos'))
             if(bankAccount==''){
                Toast('请选择银行卡')
                return false;
            }
             if(!verifyCode){
                Toast('请先获取短信验证码')
                return false;
            }
            if(checked==false){
                Toast('请同意支付还款协议')
                return false;
            }
            const result= await API.paySubmit({
                    appNo:appNum,
                	orderNo,
                    payType:payType,//还款类型，0-正常还款（单，多期还款），1-提前结清
                    cardNumber:bankAccount,
                    periods:periods, //期数列表 还款类型为0时，不能为空
                    verifyCode:verifyCode,//验证码
                // periodNos:newArray.split(','),//期数
                
            })
            if(result.repay.status==1){
                    const payResUrl=routerTag+"payResult"
                    APP.JUMP_H5(payResUrl,'支付完成') 
                    
            }else{
                Toast(result.repay.remark)
                return false
            }
            // APP.Alert(JSON.stringify(result));
            
        }
        //协议选中
        const gochencked=()=>{
                    state.checked=!state.checked
        }
        const gourl=()=>{
            APP.JUMP('https://file.jqtianxia.com/file/wage/payAgreement.html')
        }
        //银行卡弹框
        const  goback=()=>{
            // console.log(2345);
            state.showback=true
            bankinit()
        }
        const  cancel=(show)=>{            
            state.showback=show         
            state.chenckStatus=false
        }
        const  confirm=(show) =>{     
            state.showback=show    
        }
        //添加新的银行卡
        const addBank=()=>{
            state.showback=false
            //  APP.JUMP('page/bankcard')   
            const paybankUrl=routerTag+"bankcard"
            APP.JUMP_H5(paybankUrl,'添加银行卡') 
          
           
        }
        //全部结清
        const initpayconfirm = async () => {
            const orderNo=window.localStorage.getItem('orderNo');
            const periods=JSON.parse(window.localStorage.getItem('periodNos'))
            const appNum=window.localStorage.getItem('appNo')
            const result = await API.payAll({
                appNo:appNum,
                orderNo:orderNo,
                payType:1,  //还款类型，0-正常还款（单，多期还款），1-提前结清
                periods:periods //期数列表 还款类型为0时，不能为空
            })
            if(result.calc){
                //全部结清金额
                state.payAmt=result.calc.repayAmt
            }
        }
        onMounted(() => {
            // APP.Alert('344');
            APP.SET_TITLE('支付')
            const href = window.location.href
            const subIndex = href.split('?')
            console.log(href,subIndex[1],'38')
            state.payType=subIndex[1].split('=')[1]
            console.log(subIndex[1].split('=')[1],'7777')
            if(state.payType==1){
                initpayconfirm()
            }else{
                const paymoeny=window.localStorage.getItem('payAmt');
                state.payAmt=paymoeny
            }
            bankinit()
            // APP.IS_HIDDEN()//隐藏导航栏
            //
          

        })

        return{
            ...toRefs(state),
            goback,
            checkBank,
            cancel,
            confirm,
            GetVerifyCode,
            countdowns,
            paySub,
            addBank,
            changeInput,
            gochencked,
            gourl,
            // onClickLeft
        }
    }
}
</script>